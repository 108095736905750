<script>
export default {
  name: "app-back",

  // created() {
  //   console.log(this.$router);
  // },

  methods: {
    back() {
      if (history.length > 1) {
        // console.log(history.length);
        this.$router.back();
      } else {
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>

<template>
  <b-button class="app-back" @click.prevent="back">
    <!-- <b-icon icon="chevron-left" type="is-primary" /> -->
    <b-icon icon="arrow-left" />
  </b-button>
</template>
